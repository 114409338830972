import clsx from "clsx";
import { graphql, navigate, useStaticQuery } from "gatsby";
import dayjs from "dayjs";

import Heading from "@/components/elements/Heading";
import ClipPath from "@/components/elements/ClipPath";
import Image from "@/components/elements/Image";
import Tag from "@/components/elements/Tag";
import useDataSource from "@/base/hooks/useDataSource";
import LinkComponent from "@/components/elements/LinkComponent";
import HorizontalCard from "@/components/elements/HorizontalCard";

import { fathomEventsAvailable } from "@/base/utils/fathom-analytics";
import { useLocation } from "@reach/router";
import * as styles from "./styles.module.scss";

export function EventsHero({
  title,
  description,
  hero_image,
  labels,
}: EventsHeroType) {
  const { getNameByValue: getCategoryName } =
    useDataSource<CategoryType>("categories");
  const { getNameByValue: getLabelName } = useDataSource<LabelType>("labels");
  const { pathname } = useLocation();
  const { events } = useStaticQuery<{
    events: {
      nodes: {
        full_slug: string;
        content: string;
        field_event_date_string: string;
      }[];
    };
  }>(graphql`
    query {
      events: allStoryblokEntry(
        filter: {
          field_component: { eq: "template_event" }
          field_event_is_finished_boolean: { eq: false }
        }
        sort: { fields: field_event_date_string, order: ASC }
      ) {
        nodes {
          full_slug
          content
        }
      }
    }
  `);

  const activeEvents = events.nodes.map((event) => {
    const parsedContent = JSON.parse(event.content);
    return {
      heroImage: parsedContent.event_thumbnail,
      publishedDate: parsedContent.event_date,
      title: parsedContent.event_title,
      excerpt: parsedContent.event_description,
      categories: parsedContent.event_categories,
      url: `/${event.full_slug}`,
      labels: parsedContent.event_labels,
      registerUrl: parsedContent.event_register_url,
      getNameByValue: getCategoryName,
    };
  });

  const featuredEvent = activeEvents[0];
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.headingContent}>
          <Heading level={1}>{title}</Heading>
          <p className={styles.description}>{description}</p>
          {hero_image && (
            <div className={styles.imageWrapper}>
              {Array.isArray(labels) && (
                <div className={styles.tagWrapper}>
                  {labels?.map((label) => (
                    <Tag
                      size="small"
                      key={label}
                      type={label}
                      text={getLabelName(label)}
                    />
                  ))}
                </div>
              )}
              <ClipPath version={2} className={styles.eventsImage}>
                <Image data={hero_image} fluid />
              </ClipPath>
            </div>
          )}
          {featuredEvent && (
            <div
              className={styles.upcomingEvent}
              onClick={() => navigate(featuredEvent.url)}
              role="link"
              tabIndex={0}
              onKeyDown={() => navigate(featuredEvent.url)}
            >
              <span>
                {dayjs(featuredEvent.publishedDate).format("DD MMMM YYYY")}
              </span>
              <Heading level={3}>{featuredEvent.title}</Heading>
              <p>{featuredEvent.excerpt}</p>
              {featuredEvent.categories.length > 0 && (
                <div className={styles.eventTags}>
                  {featuredEvent.categories.map((category: CategoryType) => (
                    <Tag
                      size="small"
                      key={category}
                      text={getCategoryName(category)}
                    />
                  ))}
                </div>
              )}
              {featuredEvent.registerUrl && (
                <LinkComponent
                  url={featuredEvent.registerUrl}
                  type="external"
                  with_icon
                  variant="button_outlined"
                  dataAnalytics={{
                    eventName: fathomEventsAvailable["register-event"],
                    props: {
                      location: pathname,
                      title: featuredEvent.title,
                      details: "Featured event on hero",
                    },
                  }}
                >
                  Register
                </LinkComponent>
              )}
            </div>
          )}
        </div>

        {activeEvents.length > 0 && (
          <div
            className={clsx(
              {
                [styles.withEvent]: !!featuredEvent && activeEvents.length > 1,
              },
              styles.cardContainer
            )}
          >
            {activeEvents.slice(1).map((event) => (
              <HorizontalCard key={event.title} {...event} />
            ))}
          </div>
        )}
      </div>
      <img
        src="/svgs/eventsShape.svg"
        alt="events shape"
        className={styles.shape}
      />
      <img
        src="/svgs/eventsFlower.svg"
        alt="events flower"
        className={styles.flower}
      />
    </section>
  );
}
