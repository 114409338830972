import { graphql, useStaticQuery } from "gatsby";

import useDataSource from "@/base/hooks/useDataSource";

import LinkComponent from "@/components/elements/LinkComponent";
import RichText from "@/components/elements/RichText";

import { fathomEventsAvailable } from "@/base/utils/fathom-analytics";
import { useLocation } from "@reach/router";
import { usePaginatedNews } from "./hooks/use-news";
import { formatNewsResult } from "./lib/format-news-result";
import { PaginatedNews } from "./paginate-news/PaginatedNews";
import * as styles from "./styles.module.scss";

function PaginateNews() {
  const { searchResults } = usePaginatedNews();
  const { getNameByValue } = useDataSource<CategoryType>("categories");

  const { site } = useStaticQuery<{
    site: {
      siteMetadata: {
        ogImage: StoryblokGatsbyImageAsset;
      };
    };
  }>(graphql`
    query NewsQuery {
      site {
        siteMetadata {
          ogImage
        }
      }
    }
  `);

  if (!searchResults) {
    return <p className={styles.noResults}>Loading news...</p>;
  }

  const allNews = formatNewsResult({
    searchResults,
    getNameByValue,
    site,
  }).filter((item) => item?.heroImage.image) as unknown as HitNews[];

  if (allNews.length === 0) {
    return <p className={styles.noResults}>No results.</p>;
  }

  return (
    <div>
      {allNews.length && (
        <PaginatedNews itemsPerPage={6} newsArticles={allNews} />
      )}
    </div>
  );
}

export function NewsHero({ title }: NewsHeroType) {
  const { pathname } = useLocation();
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.hero}>
          <div>
            <span className={styles.subheading}>News</span>
            {title && <RichText content={title} />}
          </div>
          <div className={styles.newsletterContainer}>
            <div className={styles.newsletterBox}>
              <span>Newsletter</span>
              <p>
              Don't miss out on the latest research on plant-based eating and updates from Alpro Foundation.
              </p>

              <LinkComponent
                url="/newsletter"
                type="internal"
                variant="button_primary"
                dataAnalytics={{
                  eventName: fathomEventsAvailable["sign-up-newsletter-news"],
                  props: {
                    location: pathname,
                    details:
                      "This is not a Sign up action is a Redirect to newsletter page",
                  },
                }}
              >
                Sign up to our newsletter
              </LinkComponent>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <PaginateNews />
      </div>
    </section>
  );
}
